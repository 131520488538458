<template>
  <div>
    <vxe-modal
      v-model="open"
      width="30%"
      height="50%"
      :title="title"
      @close="handleClose"
      @zoom="handleZoom"
      show-zoom
      resize
      :lock-view="false"
      :mask="true"
      :esc-closable="true"
      :position="{ top: '4%', left: '29%' }"
      remember
      transfer
      ref="vxeModalRef"
    >
      <div class="container" ref="containerRef"></div>
    </vxe-modal>
  </div>
</template>

<script>
import * as echarts from 'echarts'
// import { getChartInterval } from '@/utils/util'
import { LogDelayStatis } from '@/utils/delayTest'
// import LocalData from './DelayTimeLog'

export default {
  data() {
    return {
      open: false,
      title: '',
      option: {
        color: ['#5c7bd5', '#91cc80'],
        title: [
          {
            left: 'center',
            text: 'total delay time(ms)'
          },
          {
            top: '55%',
            left: 'center',
            text: 'ack return diff time(ms)'
          }
        ],
        tooltip: {
          // 触发机制
          trigger: 'axis',
          formatter(params) {
            return params[0].name
          }
        },
        xAxis: [
          {
            data: []
          },
          {
            data: [],
            gridIndex: 1
          }
        ],
        yAxis: [
          {
            type: 'value',
            min: 0,
            max: 2000
          },
          {
            type: 'value',
            min: 0,
            max: 2000,
            gridIndex: 1
          }
        ],
        grid: [
          {
            bottom: '60%'
          },
          {
            top: '60%'
          }
        ],
        series: [
          {
            type: 'line',
            showSymbol: false,
            data: []
            // 设置值格式化
            // tooltip: {
            //   // valueFormatter: (value) => value + ' ml'
            // }
          },
          {
            type: 'line',
            showSymbol: false,
            data: [],
            xAxisIndex: 1,
            yAxisIndex: 1
            // tooltip: {
            //   valueFormatter: (value) => value + ' ml'
            // }
          }
        ]
      }
    }
  },
  mounted() {
    this.loadChart()
    window.addEventListener('resize', () => {
      this.myChart && this.myChart.resize()
    })
  },
  methods: {
    handleZoom() {
      console.log('handleZoom')
      if (!this.$refs.vxeModalRef) {
        return
      }
      if (this.$refs.vxeModalRef.isMaximized()) {
        this.myChart && this.myChart.resize()
      } else {
        this.myChart && this.myChart.resize()
      }
    },
    showModal(vehicleId) {
      this.title = vehicleId + '-遥控命令延迟统计分析'
      this.open = true
      setInterval(() => {
        this.setOption(LogDelayStatis())
        // this.setOption(LocalData)
      }, 1000)
    },
    handleClose() {
      this.open = false
    },
    loadChart() {
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.myChart = echarts.init(this.$refs.containerRef)
        })
      })
    },
    setOption(data) {
      // 判断图表库是否加载完成
      if (!echarts) {
        setTimeout(() => {
          this.setOption(data)
        }, 500)
        return
      }
      const totalDelayTimeArray = data.delayTime
      const ackReturnTimeArray = data.ackReturnTime
      const n = ackReturnTimeArray.length
      const ackDiffArray = []
      const xData = []
      for (let k = 0; k < n; k++) {
        const time = ackReturnTimeArray[k + 1] - ackReturnTimeArray[k]
        ackDiffArray.push(time)
        xData.push(k)
      }
      // this.option.yAxis.interval = 1000
      // this.option.xAxis.data = totalDelayTimeArray.length
      this.option.series[0].data = totalDelayTimeArray
      this.option.series[1].data = ackDiffArray
      this.option.xAxis[0].data = xData
      this.option.xAxis[1].data = xData

      this.option.yAxis[0].interval = 100
      this.option.yAxis[1].interval = 100

      if (!this.myChart) {
        this.myChart = echarts.init(this.$refs.containerRef)
      }
      this.option && this.myChart.setOption(this.option)
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
}
</style>
