var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vxe-modal",
        {
          ref: "vxeModalRef",
          attrs: {
            width: "30%",
            height: "50%",
            title: _vm.title,
            "show-zoom": "",
            resize: "",
            "lock-view": false,
            mask: true,
            "esc-closable": true,
            position: { top: "4%", left: "29%" },
            remember: "",
            transfer: "",
          },
          on: { close: _vm.handleClose, zoom: _vm.handleZoom },
          model: {
            value: _vm.open,
            callback: function ($$v) {
              _vm.open = $$v
            },
            expression: "open",
          },
        },
        [_c("div", { ref: "containerRef", staticClass: "container" })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }